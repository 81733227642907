import Single1 from "./1.jpg";
import Single2 from "./2.jpg";
import Single3 from "./3.jpg";
import Single4 from "./4.jpg";
import Single5 from "./5.jpg";
import Single6 from "./6.jpg";
import Single7 from "./7.jpg";
import Single8 from "./8.jpg";
import Single9 from "./9.jpg";
import Single10 from "./10.jpg";
import Single11 from "./11.jpg";
import Single12 from "./12.jpg";
import Single13 from "./13.jpg";
import Single14 from "./14.jpg";
import Single15 from "./15.jpg";
import Single16 from "./16.jpg";
import Single17 from "./17.jpg";
import Single18 from "./18.jpg";
import Single19 from "./19.jpg";
import Single21 from "./21.jpg";
import Single22 from "./22.jpg";
import Single23 from "./23.jpg";
import Single24 from "./24.jpg";
import Single25 from "./25.jpg";
import Single26 from "./26.jpg";
import Single27 from "./27.jpg";
import Single28 from "./28.jpg";
import Single29 from "./29.jpg";
import Single30 from "./30.jpg";
import Single31 from "./31.jpg";
import Single32 from "./32.jpg";
import Single33 from "./33.jpg";
import Single34 from "./34.jpg";
import Single35 from "./35.jpg";
import Single37 from "./37.jpg";

export const workshopImages = [
    Single1,
    Single2,
    Single3,
    Single4,
    Single5,
    Single6,
    Single7,
    Single8,
    Single9,
    Single10,
    Single11,
    Single12,
    Single13,
    Single14,
    Single15,
    Single16,
    Single17,
    Single18,
    Single19,
    Single21,
    Single22,
    Single23,
    Single24,
    Single25,
    Single26,
    Single27,
    Single28,
    Single29,
    Single30,
    Single31,
    Single32,
    Single33,
    Single34,
    Single35,
    Single37,
];
