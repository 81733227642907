import React, { Fragment } from "react";
import Davit1 from "../../../images/CV.png";
import Fade from "@material-ui/core/Fade";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import GetAppIcon from "@material-ui/icons/GetApp";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { experience, roles, travels, practices } from "./data";
import TimeLine from "./Timeline";
import withWidth from "@material-ui/core/withWidth";
import Helmet from "react-helmet";
import "./style.css";
const Homepage = props => {
  const [scrollY, setScrollY] = React.useState(window.scrollY);
  React.useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });
  const isMobile = () => {
    if (props.width === "xs" || props.width === "sm") {
      return true;
    } else {
      return false;
    }
  };
  return (
    <Fragment>
      {!isMobile() && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Cv</title>
          <link rel="canonical" href="https://davitbaroyan.com/cv" />
        </Helmet>
      )}
      {!isMobile() && (
        <Fade in={true} timeout={3000}>
          <div
            style={{
              backgroundRepeat: "no-repeat",
              minHeight: "100%",
              height: "100%",
              position: "fixed",
              top: 0,
              zIndex: "-1"
            }}
          >
            <img
              style={{
                backgroundRepeat: "no-repeat",
                minHeight: "100%",
                //  minWidth: 1024,
                //  width: "100%",
                transform: "scale(1.05)",
                height: "100%",
                position: "fixed",
                top: 0,
                left: -20,
                zIndex: "-1"
              }}
              src={Davit1}
              alt="Davit"
            />
          </div>
        </Fade>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end"
          //  marginRight: 137
        }}
      >
        <div
          style={{
            width: "100%",
            //  paddingRight: !isMobile() && 100,
            paddingTop: isMobile() && 50
          }}
        >
          <Typography
            style={{ textAlign: "center", color: "lightgray" }}
            variant="h6"
          >
            Doświadczenie teatralne
          </Typography>
          <TimeLine data={experience} isMobile={isMobile} />
          <Typography
            style={{ textAlign: "center", color: "lightgray" }}
            variant="h6"
          >
            Role aktorskie
          </Typography>
          <TimeLine data={roles} isMobile={isMobile} />
          <Typography
            style={{ textAlign: "center", color: "lightgray" }}
            variant="h6"
          >
            Festiwale teatralne i podróże
          </Typography>
          <TimeLine data={travels} isMobile={isMobile} />

          <Typography
            style={{ textAlign: "center", color: "lightgray" }}
            variant="h6"
          >
            Praktyki i Warsztaty
          </Typography>
          <TimeLine data={practices} isMobile={isMobile} />

          <Button
            component={Link}
            target="_blank"
            href="./CV.pdf"
            style={{
              margin: 12,
              marginLeft: !isMobile() ? "calc(50% - 220px)" : "6px",
              marginRight: !isMobile() ? "calc(50% - 220px)" : "6px",
              marginBottom: 96,
              width: !isMobile() ? "440px" : "calc(100% - 24px)",
              //float: "right",
              fontWeight: 700,
              color: "white"
            }}
            variant="outlined"
          >
            <GetAppIcon style={{ paddingRight: 12 }} /> Pobierz jako PDF
          </Button>
        </div>
      </div>

      {/*
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div
            style={{
              width: !isMobile() ? "70%" : "100%",
              paddingRight: !isMobile() && 100,
              paddingTop: isMobile() && 50
            }}
          >
            <VerticalTimeline>
              {data().map((data, index) => (
                <VerticalTimelineElement
                  position={"right"}
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    backgroundColor: "#4c4c4c",
                    display: "inline-block",
                    boxShadow: "none",
                    borderRadius: "10px",
                    color: "#fff"
                  }}
                  contentArrowStyle={
                    {
                      //  borderRight: "7px solid  rgb(33, 150, 243)"
                    }
                  }
                  iconStyle={{ background: "gray" }}
                  //  icon={<WorkIcon />}
                >
                  <h4
                    style={{
                      color: data.category.color,
                      width: "100%",
                      paddingBottom: 12
                    }}
                    className="vertical-timeline-element-title"
                  >
                    {data.date}
                  </h4>
                  <h3
                    style={{ color: data.category.color }}
                    className="vertical-timeline-element-title"
                  >
                    {data.category.tag}
                  </h3>
                  {data.link && (
                    <a
                      href={data.link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {data.link.text}
                    </a>
                  )}
                  <p>{data.text}</p>
                </VerticalTimelineElement>
              ))}
            </VerticalTimeline>
          </div>{" "}
        </div>
      */}
      {/*

      <div
        style={{
          color: "#fff",
          paddingLeft: 500,
          paddingRight: 40,
          overflow: "auto"
        }}
      >
        <h2>DAVIT BAROYAN</h2>
        <p>
          Dane osobowe Urodzony: 7 stycznia 1992 Tel: +48 791 219 363 Email:
          daw.baro@gmail.com
        </p>
        <h2>WYKSZTAŁCENIE</h2>
        <p>
          <strong>2012 - 2014r.</strong>
          Państwowe Pomaturalne Studium Kształcenia Animatorów Kultury we
          Wrocławiu - specjalizacja teatralna
          <strong>2013 - 2016r.</strong>
          Wyższa Szkoła Humanistyczna p.k. w Nysie - dziennikarstwo
        </p>
        <h2>DOŚWIADCZENIE TEATRALNE</h2>
        <ul>
          <li>
            <strong>2014 - do teraz:</strong> Teatr ZAR, Wrocław
          </li>
          <li>
            <strong>2010 - 2014:</strong> Teatr BRAMA, Goleniów
          </li>
          <li>
            <strong>2007 - 2012:</strong> Teatr Piktogram, Opole
          </li>
        </ul>
        <h2>ROLE AKTORSKIE</h2>
        <ul>
          <li>
            <strong>2019 - do teraz:</strong> ANHELLI. SKOWYT reż. Jarosław Fret
            (Teatr ZAR)
          </li>
          <li>
            <strong>2019 - do teraz:</strong> TROCINY (monodram Davita Baroyana,
            reż. Jarosław Fret)
          </li>
          <li>
            <strong>2017 - 2019:</strong> TECHNOLOGIA JEST ISTOTĄ reż. Teo
            Dumski (Cloud Teater)
          </li>
          <li>
            <strong>2017 - 2018:</strong> TRIODRAM reż. Katarzyna Pawłowska
            (indywidualny projekt Davita Baroyana, Dominika Smaruja, Huberta
            Kożuchowskiego w ramach Akademii Teatru Alternatywnego z ramienia
            Ministerstwa Kultury i Dziedzictwa Narodowego, Instytutu im. Jerzego
            Grotowskiego, Teatru Kana, Teatru Brama)
          </li>
          <li>
            <strong>2016 - do teraz:</strong> MEDEE. O PRZEKRACZANIU reż.
            Jarosław Fret (Teatr ZAR)
          </li>
          <li>
            <strong>2014 - 2017:</strong> ARMINE,SISTER reż. Jarosław Fret
            (Teatr ZAR)
          </li>
          <li>
            <strong>2010 - 2012:</strong> APOTEOZA - UCZUCIE W DŹWIĘKU reż.
            Daniel Jacewicz (Teatr Bama)
          </li>
          <li>
            <strong>2010 - 2012:</strong> Faust - 7 grzechów głównych reż.
            Dariusz Kowcun (Teatr Piktogram)
          </li>
          <li>
            <strong>2009 - 2010:</strong> DRUGI POKÓJ reż. Dariusz Kowcun (Teatr
            Piktogram)
          </li>
        </ul>
        FESTIWALE TEATRALNE I PODRÓŻE 2019 r. Olimpiada Teatralna w Todze
        (Japonia) i Sankt-Petersburgu (Rosja) ANHELLI. SKOWYT, teatr ZAR Teatr X
        w Tokio, Japonia ANHELLI. SKOWYT, teatr ZAR Festiwal Pieśni
        Polifonicznych, Sardynia Teatr ZAR 2018 r. Teatr Bouffes Du Nord w
        Paryżu, Francja MEDEE. O PRZEKRACZANIU, teatr ZAR Frankfurt Nad Niemiem,
        Niemcy KRÓTKI ZARYS WSZYSTKIEGO, Cloud Theater Top OFF Festiwal w
        Tychach Technologia jest istotą, Cloud Theater 2017 r. 38 Przegląd
        Piosenki Aktorskiej, Wrocław Technologia jest istotą, Cloud Theater
        Festiwal OFTeN w Ostrowie Wielkopolskim; nagroda Grand Prix Triodram
        Forest Festival w Salonikach, Grecja Medee. O Przekraczaniu, teatr ZAR
        Festiwal OFMAT w Krakowie; Triodram 3 nagrody: 1. Złoty OFMAT - Nagroda
        Redakcji 2. Nagroda Młodego Jury 3. III-cie wyróżnienie Iberico Theater
        w Lizbonie, Portugalia ARMINE,SISTER, teatr ZAR Naves Matadero w
        Madrycie, Hiszpania ARMINE,SISTER oraz MEDEE.O PRZEKRACZANIU, teatr ZAR
        2016 r. International Theatre Festival w Sibiu, Rumunia ARMINE,SISTER,
        teatr ZAR Olimpiada Teatralna we Wrocławiu MEDEE. O PRZEKRACZANIU, teatr
        ZAR 2015 r. Kanonhallen w Oslo, Norwegia ARMINE,SISTER, teatr ZAR Fort
        Mason Center w San Francisco, Stany Zjednoczone ARMINE,SISTER, teatr ZAR
        Teatro India w Rzymie, Włochy ARMINE,SISTER, teatr ZAR Festiwal SPOIWA w
        Szczecinie, Polska ARMINE,SISTER, teatr ZAR Theatre de la Tempete w
        Paryżu, Francja ARMINE,SISTER, teatr ZAR 2014 r. Battersea Arts Centre w
        Londynie, Anglia ARMINE, SISTER, teatr ZAR PRAKTYKI I WARSZTATY 2018 -
        2019 r. Trening indywidualny z: Kameron Steele - Suzuki Company of Toga;
        Savvas Stroumpos - Theatre ATTIS; Vivien Wood - DV8 Physical Theatre;
        Przemysław Błaszczak - teatr ZAR, Studio Kokyu; Jakub Gontarski -
        instruktor rekreacji ruchowej ze specjalizacją samoobrona, kaskader,
        certyfikowany trener personalny oraz trener ruchu pod kierunkiem Steve'a
        Maxwell'a; Praktyka pieśni korsykańskich w Pianello, Korsyka. 2014 -
        2017 r. Akademia Teatru Alternatywnego: projekt z ramienia Ministerstwa
        Kultury i Dziedzictwa Narodowego, Instytutu im. Jerzego Grotowskiego,
        teatru Kana, teatru Brama. Warsztaty i treningi m.in. z Teatrem Chorea,
        Trzecim Teatrem Lecha Raczaka, Teatrem ATTIS, Studiem Teatralnym z
        Warszawy, Teatrem Cinema, Teatrem Kana, Akademią Ruchu, Teatrem Stella
        Polaris, Teatr im. Łesia Kurbasa, Anną Zubrzycki, Artim Grabowskim i
        Jackiem Hałasem. Aram Kerovpyan - praktyka modalnych pieśni ormiańskich;
        Jakub Gontarski - Studio Dwóch Ścieżek - BodyConstitution; Savvas
        Stroumpos - Theatre ATTIS UMIEJĘTNOŚĆI - śpiew - gitara akustyczna -
        pływanie - tenis stołowy - piłka nożna - prawo jazdy kat. B JĘZYKI OBCE
        rosyjski - komunikatywnie angielski - biegle ormiański - biegle
      </div>*/}
      <Slide direction="up" in={scrollY !== 0}>
        <IconButton
          style={{ position: "fixed", bottom: 12, right: "calc(50% - 24px)" }}
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth"
            });
          }}
        >
          <KeyboardArrowUpIcon
            style={{
              fontSize: 40,
              color: "rgba(255,255,255,0.7)"
            }}
          />
        </IconButton>
      </Slide>
    </Fragment>
  );
};

export default withWidth()(Homepage);
