import React, { Fragment } from "react";
import Davit1 from "../../../images/BIO.png";
import Fade from "@material-ui/core/Fade";
import { Switch, Route, Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import Helmet from "react-helmet";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
const useStyles = makeStyles({
  photo: {
    backgroundRepeat: "no-repeat",
    minHeight: "100%",
    //  minWidth: 1024,
    //  width: "100%",
    transform: "scale(1.03)",
    height: "100%",
    position: "fixed",
    top: 0,
    left: -80,
    zIndex: "-1",
  },
  ul: {
    listStyleType: "none",
    margin: 0,
    padding: 0,
    overflow: "hidden",
  },
  container: {
    display: "flex",
    paddingLeft: "30%",
    paddingRight: "15%",
    color: "#fff",
    paddingTop: 32,
  },
  textBlock: {
    width: "66%",
    textAlign: "justify",
    paddingLeft: 16,
    paddingRight: 32,
  },
  bioBlock: {
    paddingTop: 0,
    width: "30%",
    whiteSpace: "nowrap",
  },
  bioBlockMobile: {
    paddingTop: 0,
    color: "white",
    textAlign: "center",
    width: "100%",
    paddingBottom: 32,
  },
  paragraph: {
    paddingBottom: 64,
    textAlign: "justify",
  },
  paragraphMobile: {
    padding: 32,
    textAlign: "justify",
  },
  // firstBlock:
  // {
  //     flexDirection: "column",
  //     display: "flex",
  //     justifyContent: "center",
  //     overflow: "auto",
  //     alignItems: "left",
  //     fontSize: 60
  // }
});

//import data from "./data";
//import TimeLine from "./Timeline";

const Homepage = (props) => {
  const [scrollY, setScrollY] = React.useState(window.scrollY);
  React.useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });
  const isMobile = () => {
    if (props.width === "xs" || props.width === "sm") {
      return true;
    } else {
      return false;
    }
  };
  const classes = useStyles();
  return (
    <Fragment>
      {!isMobile() && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Bio</title>
          <link rel="canonical" href="https://davitbaroyan.com/bio" />
        </Helmet>
      )}
      {!isMobile() && (
        <Fade in={true} timeout={3000}>
          <div className={classes.photo}>
            <img className={classes.photo} src={Davit1} alt="Davit" />
          </div>
        </Fade>
      )}
      <div
        className={!isMobile() && classes.container}
        style={{ paddingTop: isMobile() && 50 }}
      >
        <div
          className={!isMobile() && classes.textBlock}
          // style={{ color: isMobile() && "#847d7d" }}
        >
          <Typography
            variant="body1"
            className={isMobile() ? classes.paragraphMobile : classes.paragraph}
          >
            Urodziłem się w 1992 roku w Armenii, od 2 roku życia mieszkam w
            Polsce. Od 2014 roku pracuję jako aktor w Teatrze ZAR działającym
            przy Instytucie im. Jerzego Grotowskiego we Wrocławiu.
            <br />
            Spektakle -
            „Armine, Sister” ; „ Medee. O przekraczaniu” ; „ Anhelli. Skowyt” ;
            „ Złowione”. Od 2022 roku prowadzę zajęcia w Akademii Sztuk
            Teatralnych we Wrocławiu z przedmiotu Kultura Ciała.
            <br />
            <br />
            Jestem absolwentem Dziennikarstwa i Animacji Kultury we Wrocławiu
            oraz dwóch edycji Akademii Teatru Alternatywnego ( 2012 i 2016 r.)
            Współpracowałem z reżyserami teatralnymi takimi jak Hiroshi Koike,
            Leszek Mądzik czy Daniel Jacewicz.
            <br />
            <br />
            Wraz z teatrem ZAR miałem możliwość występowania na międzynarodowych
            festiwalach teatralnych m.in. w Tokio, Rzymie, Sankt-Petersburgu,
            Oslo, Madrycie, Sibiu czy San Francisco. Mam również na swoim koncie
            monodram Trociny na podstawie książki Krzysztofa Vargi. Na początku
            2023 roku zagrałem w spektaklu KOSMOS Witolda Gombrowicza w
            reżyserii Hiroshiego Koike, a w marcu 2024 roku pokaz odbył się
            również w Tokyo Metropolitan Theatre w Japonii.
            <br />
            <br />
            Od 2022 roku współorganizuję i prowadzę jako pedagog cykl treningów
            aktorskich ZAR INTENSE skierowanych do praktyków teatru z całego
            świata. Prowadzę warsztaty teatralne dla amatorów jak i dla aktorów
            nie tylko polskich, ale i zagranicznych. Gram na gitarze, jako hobby
            nagrywam rap , lubię długie spacery, szczere rozmowy i pracę z
            drugim człowiekiem.
          </Typography>

          {/* <Typography variant="subtitle1" gutterBottom>PYTONG: 70cm, 5,5kg zdrowego miecha, sama sprawdź. Nie wierzysz, wbijaj na Wolbro,<br /> tylko się najpierw zapisz w kajecie na korytarzu :)</Typography>
        <Typography variant="subtitle1" gutterBottom>PS: Jakbym miał już towarzystwo (raczej będę miał), to żeby przeczekać, wbijaj do ziomka na pierwszym piętrze,<br/> jest całkiem miły, choć to informatyk. Uśmiechnij się do niego, zagadaj, a zrobi Ci stronę za Free</Typography>
        */}
        </div>
        <div
          className={isMobile() ? classes.bioBlockMobile : classes.bioBlock}
          style={{ position: "relative" }}
        >
          <div style={{ position: "fixed" }}>
            <Typography variant="subtitle1" gutterBottom>
              WZROST:{" "}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              173 cm
            </Typography>
            <br />
            <Typography variant="subtitle1" gutterBottom>
              OBWÓD KLATKI PIERSIOWEJ:
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              103 cm
            </Typography>
            <br />
            <Typography variant="subtitle1" gutterBottom>
              BIODRA:
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              100 cm
            </Typography>
            <br />
            <Typography variant="subtitle1" gutterBottom>
              TALIA, OBWÓD PASA:
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              83 cm{" "}
            </Typography>
            <br />
            <Typography variant="subtitle1" gutterBottom>
              KOŁNIERZ:
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              37 cm
            </Typography>
            <br />
            <Typography variant="subtitle1" gutterBottom>
              OBWÓD GŁOWY:
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              55,5 cm
            </Typography>
          </div>
        </div>
      </div>
      <Slide direction="up" in={scrollY !== 0}>
        <IconButton
          style={{ position: "fixed", bottom: 12, right: "calc(50% - 24px)" }}
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
        >
          <KeyboardArrowUpIcon
            style={{
              fontSize: 40,
              color: "rgba(255,255,255,0.7)",
            }}
          />
        </IconButton>
      </Slide>
    </Fragment>
  );
};

export default withWidth()(Homepage);
