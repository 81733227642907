import React, { Fragment } from "react";
import Davit1 from "../../../images/START.png";
import Fade from "@material-ui/core/Fade";
import Grow from "@material-ui/core/Grow";
import Typography from "@material-ui/core/Typography";

const Homepage = () => {
  const [textScaled, setTextScaled] = React.useState(false);
  const [secondTextVisible, setSecondTextVisible] = React.useState(false);
  const nameRef = React.createRef();
  React.useEffect(() => {
    setTimeout(() => setTextScaled(true), 1300);
    setTimeout(() => setSecondTextVisible(true), 1600);
  }, []);
  return (
    <Fragment>
      <Fade in={true} timeout={3000}>
        <div
          style={{
            backgroundRepeat: "no-repeat",
            minHeight: "100%",
            height: "100%",
            position: "fixed",
            top: 0,
            zIndex: "-1"
          }}
        >
          <img
            style={{
              backgroundRepeat: "no-repeat",
              minHeight: "100%",
              //  minWidth: 1024,
              //  width: "100%",
              height: "100%",
              position: "fixed",
              top: 0,
              left: -350,
              zIndex: "-1"
            }}
            src={Davit1}
            alt="Davit"
          />
        </div>
      </Fade>
      {/* <Fade in={true} timeout={7000}>
            <div style={{ position: "fixed", height: 30, width: "100%", marginTop: 200, zIndex: "-3", backgroundColor: "#222" }} />
        </Fade>  */}
      <Fade in={true} timeout={5000}>
        <div
          ref={nameRef}
          style={{
            color: "#fff",
            display: "block",
            //fontSize: "3vw",
            position: "absolute",
            width: "100%",
            minHeight: 200,
            top: "calc(50% - 100px)",
            justifyContent: "center",
            overflow: "auto",
            alignItems: "center",
            transition: "font 3s ease"
          }}
        >
          <Typography
            variant={"h1"}
            style={{
              //fontSize: "3vw",
              fontWeight: 200,
              fontFamily: "inherit",
              marginLeft: 270,
              marginTop: 0,
              marginBottom: 0,
              textAlign: "center",
              transition: "all 0.7s ease",
              transform: textScaled ? "scale(0.8)" : "scale(0.7)"
            }}
          >
            DAVIT BAROYAN
          </Typography>

          {/*secondTextVisible && (
            <Fade in={secondTextVisible}>
              <Typography
                variant="h4"
                style={{
                  fontWeight: 200,
                  fontFamily: "inherit",
                  marginLeft: 270,
                  marginTop: 0,
                  marginBottom: 0,
                  color: "gray",
                  textAlign: "center"
                }}
              >
                Aktor teatru ZAR
              </Typography>
            </Fade>
          )*/}
        </div>
      </Fade>
    </Fragment>
  );
};

export default Homepage;
