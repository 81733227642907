import React, { Fragment, useState } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import Helmet from "react-helmet";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { photographyImages } from "./images/PhotographyImages"
import Lightbox from "react-image-lightbox";

const LazyImage = ({
  image,
  isMobile,
  setCurrentPhoto,
  setGalleryOpen,
  height,
  width,
}) => {
  let shrinkPercentage = Math.floor(
    (isMobile ? window.innerWidth / width : 750 / image.width) * 100
  );
  let realHeight = parseInt(image.height * (shrinkPercentage / 100));
  const IMAGE_WIDTH_PERCENT = 85;

  return (
    <LazyLoadImage
      onClick={() => {
        setCurrentPhoto();
        setGalleryOpen(true);
      }}
      alt={image.alt}
      effect="blur"
      src={image.src}
      style={{
        paddingBottom: 12,
        width: !isMobile ? `${IMAGE_WIDTH_PERCENT}%` : "100%",
        height: realHeight,
        paddingRight: !isMobile && `calc(50% - ${IMAGE_WIDTH_PERCENT / 2}%)`,
        paddingLeft: !isMobile && `calc(50% - ${IMAGE_WIDTH_PERCENT / 2}%)`,
        cursor: "pointer",
      }}
    />
  );
};

const useStyles = makeStyles({
  photo: {
    backgroundRepeat: "no-repeat",
    minHeight: "100%",
    transform: "scale(1.03)",
    height: "100%",
    position: "fixed",
    top: 0,
    left: 20,
    zIndex: "-1",
  },
  ul: {
    listStyleType: "none",
    margin: 0,
    padding: 0,
    overflow: "hidden",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    color: "#fff",
    paddingTop: 32,
  },
  textBlock: {
    width: "66%",
    textAlign: "justify",
    paddingLeft: 16,
    paddingRight: 32,
  },
  paragraph: {
    paddingBottom: 64,
    textAlign: "justify",
  },
  paragraphMobile: {
    padding: 32,
    textAlign: "justify",
  },
});

const Workshop = (props) => {
  const [scrollY, setScrollY] = React.useState(window.scrollY);
  const [currentPhoto, setCurrentPhoto] = useState(0);
  const [galleryOpen, setGalleryOpen] = useState(false);
  const imageWidth = 1200;
  const imageHeight = 1463;

  const lazyImages = (inputArray) => {
    const elements = [];
    for (let i = 0; i < inputArray.length; i++) {
      elements.push(
        <LazyImage
          key={i}
          isMobile={isMobile()}
          image={{ src: inputArray[i], alt: "photo" }}
          setGalleryOpen={setGalleryOpen}
          setCurrentPhoto={() => setCurrentPhoto(i)}
          width={imageWidth}
          height={imageHeight}
        />
      );
    }
    return elements;
  };

  const isMobile = () => {
    if (props.width === "xs" || props.width === "sm") {
      return true;
    } else {
      return false;
    }
  };
  const classes = useStyles();
  React.useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  return (
    <Fragment>
      {!isMobile() && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Workshop</title>
          <link rel="canonical" href="https://davitbaroyan.com/workshop" />
        </Helmet>
      )}

      <div
        className={!isMobile() && classes.container}
        style={{ paddingTop: isMobile() && 50 }}
      >
        <div className={!isMobile() && classes.textBlock}>
          <Typography
            variant="body1"
            className={isMobile() ? classes.paragraphMobile : classes.paragraph}
          >
            {lazyImages(photographyImages)}
          </Typography>
        </div>
      </div>
      <Slide direction="up" in={scrollY !== 0}>
        <IconButton
          style={{ position: "fixed", bottom: 12, right: "calc(50% - 24px)" }}
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
        >
          <KeyboardArrowUpIcon
            style={{
              fontSize: 40,
              color: "rgba(255,255,255,0.7)",
            }}
          />
        </IconButton>
      </Slide>
      {galleryOpen && (
        <Lightbox
          mainSrc={photographyImages[currentPhoto]}
          nextSrc={photographyImages[(currentPhoto + 1) % photographyImages.length]}
          prevSrc={
            photographyImages[
              (currentPhoto + photographyImages.length - 1) % photographyImages.length
            ]
          }
          onCloseRequest={() => setGalleryOpen(false)}
          onMovePrevRequest={() =>
            setCurrentPhoto(
              (currentPhoto + photographyImages.length - 1) % photographyImages.length
            )
          }
          onMoveNextRequest={() =>
            setCurrentPhoto((currentPhoto + 1) % photographyImages.length)
          }
        />
      )}
    </Fragment>
  );
};

export default withWidth()(Workshop);
