import React, { Fragment } from "react";
import Davit1 from "../../../images/01.png";
import Fade from "@material-ui/core/Fade";
import List from "@material-ui/core/List";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import EmailIcon from "@material-ui/icons/Email";
import withWidth from "@material-ui/core/withWidth";
import Helmet from "react-helmet";
const Homepage = props => {
  const isMobile = () => {
    if (props.width === "xs" || props.width === "sm") {
      return true;
    } else {
      return false;
    }
  };
  return (
    <Fragment>
      {!isMobile() && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Kontakt</title>
          <link rel="canonical" href="https://davitbaroyan.com/contact" />
        </Helmet>
      )}
      {!isMobile() && (
        <Fade in={true} timeout={3000}>
          <div
            style={{
              backgroundRepeat: "no-repeat",
              minHeight: "100%",
              height: "100%",
              position: "fixed",
              top: 0,
              zIndex: "-1"
            }}
          >
            <img
              style={{
                backgroundRepeat: "no-repeat",
                minHeight: "100%",
                //  minWidth: 1024,
                //  width: "100%",
                height: "100%",
                position: "fixed",
                top: 0,

                zIndex: "-1"
              }}
              src={Davit1}
              alt="Davit"
            />
          </div>
        </Fade>
      )}

      <div
        style={{
          color: "white",

          paddingLeft: !isMobile() && 500,
          paddingRight: !isMobile() && 40,
          padding: isMobile() && 32,
          paddingTop: isMobile() && 50,
          overflow: "auto"
        }}
      >
        <div
          style={{
            textAlign: "center",
            position: isMobile() ? "relative" : "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            height: "300px",
            width: "300px",
            paddingLeft: props.width === "md" || (props.width === "lg" && 100),
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <List>
            {/*    <ListItem>
              <ListItemIcon>
                <ContactIcon
                  style={{
                    color: !isMobile() ? "white" : "#847d7d",
                    fontSize: "2.5rem"
                  }}
                />
              </ListItemIcon>
              <p style={{ fontSize: "large" }}>Davit Baroyan</p>
            </ListItem>*/}

            <ListItem
              style={{ display: "flex", justifyContent: "space-evenly",padding:0 }}
            >
              <Button
                component={Link}
                target="_blank"
                href="https://www.facebook.com/dawit.baroyan"
              >
                <FacebookIcon
                  style={{
                    color: "white",
                    fontSize: "3.5rem"
                  }}
                />
              </Button>
              <Button
                component={Link}
                target="_blank"
                href="https://www.instagram.com/dave.baro/"
              >
                <InstagramIcon
                  style={{
                    color: "white",
                    fontSize: "3.5rem"
                  }}
                />
              </Button>
            </ListItem>

            <ListItem
              button
              component={Link}
              target="_blank"
              href="mailto:daw.baro@gmail.com"

            >
              <p style={{ fontSize: "large", margin: 0 }}>daw.baro@gmail.com</p>
            </ListItem>
          </List>
        </div>
      </div>
    </Fragment>
  );
};

export default withWidth()(Homepage);
