import React from "react";
import "./style.css";
import Typography from "@material-ui/core/Typography";
import {
  VerticalTimeline,
  VerticalTimelineElement
} from "react-vertical-timeline-component";

const Timeline = props => {
  const { data } = props;
  // return (
  //   data.length > 0 && (
  //     <div className="timeline-container">
  //       {props.data.map((item, idx) => (
  //         <TimelineItem data={item} key={idx} />
  //       ))}
  //     </div>
  //   )
  // );
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ width: !props.isMobile() ? "424px" : "95%" }}>
        <VerticalTimeline layout={"1-column"}>
          {data().map((data, index) => (
            <VerticalTimelineElement
              position={"right"}
              className="vertical-timeline-element--work"
              contentStyle={{
                border: "1px solid white",
                backgroundColor: "#4c4c4c",
                display: "inline-block",
                boxShadow: "none",
                borderRadius: "10px",
                color: "#fff"
              }}
              contentArrowStyle={
                {
                  //  borderRight: "7px solid  rgb(33, 150, 243)"
                }
              }
              iconStyle={{ background: "gray" }}
              //  icon={<WorkIcon />}
            >
              <h4
                style={{
                  color: data.category.color,
                  width: "100%",
                  paddingBottom: 12
                }}
                className="vertical-timeline-element-title"
              >
                {data.date && data.date}
              </h4>
              <h3
                style={{ color: data.category.color }}
                className="vertical-timeline-element-title"
              >
                {data.category.tag}
              </h3>
              {data.link && (
                <a
                  href={data.link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {data.link.text}
                </a>
              )}
              {Array.isArray(data.text) ? (
                data.text.map(text => {
                  return (
                    <p style={{ marginTop: 4 }}>
                      <b>{text.title}</b>
                      {text.text}
                    </p>
                  );
                })
              ) : (
                <p>{data.text}</p>
              )}
            </VerticalTimelineElement>
          ))}
        </VerticalTimeline>
      </div>
    </div>
  );
};

export default Timeline;
