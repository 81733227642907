import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import Helmet from "react-helmet";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
const useStyles = makeStyles({
  photo: {
    backgroundRepeat: "no-repeat",
    minHeight: "100%",
    //  minWidth: 1024,
    //  width: "100%",
    height: "100%",
    position: "fixed",
    top: 0,
    left: 20,
    zIndex: "-1"
  },
  ul: {
    listStyleType: "none",
    margin: 0,
    padding: 0,
    overflow: "hidden"
  },
  container: {
    display: "flex",
    justifyContent: "center",
    color: "#fff",
    padding: "0 48px"
  },
  textBlock: {
    width: 900,
    textAlign: "justify",
    paddingLeft: 16,
    paddingRight: 32
  },
  bioBlock: {
    paddingTop: 0,
    width: "30%",
    whiteSpace: "nowrap"
  },
  bioBlockMobile: {
    paddingTop: 0,
    color: "white",
    textAlign: "center",
    width: "100%",
    paddingBottom: 32
  },
  paragraph: {
    paddingBottom: 32
  },
  paragraphMobile: {
    padding: 32
  },
  gridItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: 48
  },
  gridItemMobile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "24px 12px"
  },
  gridTitle: {
    textAlign: "center",
    paddingBottom: 12
  }
  // firstBlock:
  // {
  //     flexDirection: "column",
  //     display: "flex",
  //     justifyContent: "center",
  //     overflow: "auto",
  //     alignItems: "left",
  //     fontSize: 60
  // }
});

//import data from "./data";
//import TimeLine from "./Timeline";

const Homepage = props => {
  const [scrollY, setScrollY] = useState(window.scrollY);
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });
  const isMobile = () => {
    if (props.width === "xs" || props.width === "sm") {
      return true;
    } else {
      return false;
    }
  };
  const classes = useStyles();
  return (
    <div>
      {!isMobile() && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Wideo</title>
          <link rel="canonical" href="https://davitbaroyan.com/video" />
        </Helmet>
      )}
      {/*!isMobile() && (
        <Fade in={true} timeout={3000}>
          <div className={classes.photo}>
            <img className={classes.photo} src={Davit1} alt="Davit" />
          </div>
        </Fade>
      )*/}
      <div
        className={!isMobile() && classes.container}
        style={{ paddingTop: isMobile() && 50 }}
      >
        <Grid container justify="center" alignItems="center">
          <Grid
            item
            xs={12}
            sm={6}
            className={isMobile() ? classes.gridItemMobile : classes.gridItem}
          >
            <Typography className={classes.gridTitle} variant="h5">
              "Trociny" - Trailer
            </Typography>

            <iframe
              width={"100%"}
              height="300"
              title="youtubePlayer"
              src="https://www.youtube.com/embed/3tQJvksb3tE"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            className={isMobile() ? classes.gridItemMobile : classes.gridItem}
          >
            <Typography className={classes.gridTitle} variant="h5">
              "Trociny" - teaser
            </Typography>

            <iframe
              width={"100%"}
              height="300"
              title="youtubePlayer"
              src="https://www.youtube.com/embed/b_Y2D13EWcc"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            className={isMobile() ? classes.gridItemMobile : classes.gridItem}
          >
            <Typography className={classes.gridTitle} variant="h5">
              Davit Baroyan - spot reklamowy
            </Typography>
            <iframe
              width={"100%"}
              height="300"
              title="youtubePlayer"
              src="https://www.youtube.com/embed/UYWJ2lk3g_E"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            className={isMobile() ? classes.gridItemMobile : classes.gridItem}
          >
            <Typography className={classes.gridTitle} variant="h5">
              DAVIT - 2019 (zajawka)
            </Typography>

            <iframe
              width={"100%"}
              height="300"
              title="youtubePlayer"
              src="https://www.youtube.com/embed/o7SVgcB3Cpc"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            className={isMobile() ? classes.gridItemMobile : classes.gridItem}
          >
            <Typography className={classes.gridTitle} variant="h5">
            Davit Baroyan - wizytówka
            </Typography>

            <iframe
              width={"100%"}
              height="300"
              title="youtubePlayer"
              src="https://www.youtube.com/embed/Mycv8ktCAGM"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </Grid>
        </Grid>
      </div>
      <Slide direction="up" in={scrollY !== 0}>
        <IconButton
          style={{ position: "fixed", bottom: 12, right: "calc(50% - 24px)" }}
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth"
            });
          }}
        >
          <KeyboardArrowUpIcon
            style={{
              fontSize: 40,
              color: "rgba(255,255,255,0.7)"
            }}
          />
        </IconButton>
      </Slide>
    </div>
  );
};

export default withWidth()(Homepage);
