import React, {useEffect} from "react";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
function PageWrapper(props) {
  const [expanded, setExpanded] = React.useState(false);
    const [current, setCurrent] = React.useState(true);
    useEffect(() => { 
      if (expanded == true){
          props.cvRef &&
          props.cvRef.current &&
          props.cvRef.current.scrollIntoView({
            behavior: "smooth",
          })
      }
    }, [expanded]);
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          flexDirection: "column",

          height: !expanded && "900px",
          overflow: !expanded && "hidden",
          WebkitMaskImage:
            !expanded &&
            `linear-gradient(to top, transparent 3%, black 25%, black 100%)`,
          maskImage:
            !expanded &&
            `linear-gradient(to top, transparent 3%, black 25%, black 100%)`,
          transition: "all 3s ease-in-out",
        }}
      >
        {props.children}
      </div>
      <Button
        variant="outlined"
        style={{ color: "white", borderColor: "white", marginBottom: 24 }}
        onClick={() =>{ setExpanded(!expanded);
        setCurrent("/cv");}}
      >
        {expanded ? "Zwiń" : "Czytaj dalej"}{" "}
        {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </Button>
      <Divider
        style={{ marginBottom: 24, width: "90%", backgroundColor: "gray" }}
      />
    </div>
  );
}
export default PageWrapper;
