import React, { useState } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Backgorund from "./images/background.jpg";
import Homepage from "./components/Pages/Homepage/Homepage";
import Contact from "./components/Pages/Contact/Contact";
import Cv from "./components/Pages/Cv/Cv";
import Gallery from "./components/Pages/Gallery/Gallery";
import Menu from "./components/Menu/Menu";
import Bio from "./components/Pages/Bio/Bio";
import Video from "./components/Pages/Video/Video";
import Reviews from "./components/Pages/Reviews/Reviews";
import Photography from "./components/Pages/Photography/Photography";
import withWidth from "@material-ui/core/withWidth";
import Grid from "@material-ui/core/Grid";
import Helmet from "react-helmet";
import Workshop from "./components/Pages/Workshop/Workshop";
const App = props => {
  const [pathname, setPathname] = useState(null);
  const cvRef = React.createRef();
  const bioRef = React.createRef();
  const contactRef = React.createRef();
  const videoRef = React.createRef();
  const reviewsRef = React.createRef();
  const workshopRef = React.createRef();
  const photographyRef = React.createRef();

  const isMobile = () => {
    if (props.width === "xs" || props.width === "sm") {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Davit Baroyan</title>
        <link rel="canonical" href="https://tmsolution.github.io/baroyan/" />
      </Helmet>
      <BrowserRouter basepath={""}>
        <Menu
          cvRef={cvRef}
          bioRef={bioRef}
          contactRef={contactRef}
          videoRef={videoRef}
          reviewsRef={reviewsRef}
          workshopRef={workshopRef}
          photographyRef={photographyRef}
          pathname={pathname}
        />
        <Route
          exact
          path={["/", "/contact", "/cv", "/bio", "/gallery", "/video", "/reviews", "/workshop", "/photography"]}
        >
          <div
            style={{
              backgroundImage: `url(${Backgorund})`,
              minHeight: "100%",
              minWidth: 1024,
              width: "100%",
              height: "auto",
              position: "fixed",
              top: 0,
              left: 0,
              zIndex: "-4"
            }}
          />
        </Route>

        <Switch>
          <Route
            exact
            path="/"
            component={
              isMobile()
                ? () => (
                    <Gallery
                      cvRef={cvRef}
                      bioRef={bioRef}
                      contactRef={contactRef}
                      videoRef={videoRef}
                      reviewsRef={reviewsRef}
                      workshopRef={workshopRef}
                      photographyRef={photographyRef}
                    />
                  )
                : () => <Homepage />
            }
          />
          <Route
            exact
            path="/contact"
            component={
              isMobile()
                ? () => (
                    <Gallery
                      cvRef={cvRef}
                      bioRef={bioRef}
                      contactRef={contactRef}
                      videoRef={videoRef}
                      reviewsRef={reviewsRef}
                      workshopRef={workshopRef}
                      photographyRef={photographyRef}
                    />
                  )
                : () => <Contact />
            }
          />
          <Route
            exact
            path="/cv"
            component={
              isMobile()
                ? () => (
                    <Gallery
                      cvRef={cvRef}
                      bioRef={bioRef}
                      contactRef={contactRef}
                      videoRef={videoRef}
                      reviewsRef={reviewsRef}
                      workshopRef={workshopRef}
                      photographyRef={photographyRef}
                    />
                  )
                : () => <Cv />
            }
          />
          <Route
            exact
            path="/bio"
            component={
              isMobile()
                ? () => (
                    <Gallery
                      cvRef={cvRef}
                      bioRef={bioRef}
                      contactRef={contactRef}
                      videoRef={videoRef}
                      reviewsRef={reviewsRef}
                      workshopRef={workshopRef}
                      photographyRef={photographyRef}
                    />
                  )
                : () => <Bio />
            }
          />
          <Route
            exact
            path="/workshop"
            component={
              isMobile()
                ? () => (
                    <Gallery
                      cvRef={cvRef}
                      bioRef={bioRef}
                      contactRef={contactRef}
                      videoRef={videoRef}
                      reviewsRef={reviewsRef}
                      workshopRef={workshopRef}
                      photographyRef={photographyRef}
                    />
                  )
                : () => <Workshop />
            }
          />
          <Route
            exact
            path="/photography"
            component={
              isMobile()
                ? () => (
                    <Gallery
                      cvRef={cvRef}
                      bioRef={bioRef}
                      contactRef={contactRef}
                      videoRef={videoRef}
                      reviewsRef={reviewsRef}
                      workshopRef={workshopRef}
                      photographyRef={photographyRef}
                    />
                  )
                : () => <Photography />
            }
          />
          <Route
            exact
            path="/reviews"
            component={
              isMobile()
                ? () => (
                    <Gallery
                      cvRef={cvRef}
                      bioRef={bioRef}
                      contactRef={contactRef}
                      videoRef={videoRef}
                      reviewsRef={reviewsRef}
                      workshopRef={workshopRef}
                      photographyRef={photographyRef}
                    />
                  )
                : () => <Reviews />
            }
          />
          <Route
            exact
            path="/video"
            component={
              isMobile()
                ? () => (
                    <Gallery
                      cvRef={cvRef}
                      bioRef={bioRef}
                      contactRef={contactRef}
                      videoRef={videoRef}
                      reviewsRef={reviewsRef}
                      workshopRef={workshopRef}
                      photographyRef={photographyRef}
                    />
                  )
                : () => <Video />
            }
          />
          <Route
            exact
            path="/gallery"
            component={() => (
              <Gallery
                cvRef={cvRef}
                bioRef={bioRef}
                contactRef={contactRef}
                videoRef={videoRef}
                reviewsRef={reviewsRef}
                workshopRef={workshopRef}
                photographyRef={photographyRef}
              />
            )}
          />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default withWidth()(App);
