import React, { useEffect, useState } from "react";
import Slide from "@material-ui/core/Slide";
import Fade from "@material-ui/core/Fade";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import Collapse from "@material-ui/core/Collapse";
import Image1 from "./images/gray_01.jpg";
import Image2 from "./images/gray_02.jpg";
import Image3 from "./images/gray_03.jpg";
import Image4 from "./images/gray_04.jpg";
import Image5 from "./images/gray_05.jpg";
import Image6 from "./images/gray_06.jpg";
import Image7 from "./images/gray_07.jpg";
import Image8 from "./images/gray_08.jpg";
import Image9 from "./images/gray_09.jpg";
import Image10 from "./images/gray_10.jpg";
import Image11 from "./images/gray_11.jpg";
import Image12 from "./images/gray_12.1.jpg";
import Image13 from "./images/gray_13.jpg";
import Image14 from "./images/gray_12.jpg";
import Image15 from "./images/gray_11.1.jpg";
import Image16 from "./images/gray_11_2.jpg";
import Image17 from "./images/gray_11_3.jpg";
import Image18 from "./images/gray_14.jpg";
import Image19 from "./images/gray_15.jpg";
import Image20 from "./images/gray_16.jpg";
import Image21 from "./images/gray_17.jpg";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import withWidth from "@material-ui/core/withWidth";
import {
  LazyLoadImage,
  LazyLoadComponent,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Contact from "../Contact/Contact";
import Cv from "../Cv/Cv";
import Bio from "../Bio/Bio";
import Reviews from "../Reviews/Reviews";
import Video from "../Video/Video";
import Helmet from "react-helmet";
import Single1 from "./images/1.jpg";
import Single2 from "./images/2.jpg";
import Single3 from "./images/3.jpg";
import Single4 from "./images/4.jpg";
import Single5 from "./images/5.jpg";
import Single6 from "./images/6.jpg";
import Single7 from "./images/7.jpg";
import Single8 from "./images/8.jpg";
import Single9 from "./images/9.jpg";
import Single10 from "./images/10.jpg";
import Single11 from "./images/11.jpg";
import Single12 from "./images/12.jpg";
import Single13 from "./images/13.jpg";
import Single14 from "./images/14.jpg";
import Single15 from "./images/15.jpg";
import Single16 from "./images/16.jpg";
import Single17 from "./images/17.jpg";
import Single18 from "./images/18.jpg";
import Single19 from "./images/19.jpg";
import Single20 from "./images/20.jpg";
import Single21 from "./images/21.jpg";
import Single22 from "./images/22.jpg";
import Single23 from "./images/23.jpg";
import Single24 from "./images/24.jpg";
import Single25 from "./images/25.jpg";
import Single26 from "./images/26.jpg";
import Single27 from "./images/27.jpg";
import Single28 from "./images/28.jpg";
import Single29 from "./images/29.jpg";
import Single30 from "./images/30.jpg";
import Single31 from "./images/31.jpg";
import Single32 from "./images/32.jpg";
import Single33 from "./images/33.jpg";
import Single34 from "./images/34.jpg";
import Single35 from "./images/35.jpg";
import Single36 from "./images/36.jpg";
import Single37 from "./images/37.jpg";
import Single38 from "./images/38.jpg";
import Single39 from "./images/39.jpg";
import Single40 from "./images/40.jpg";
import Single41 from "./images/41.jpg";
import Single42 from "./images/42.jpg";
import Single43 from "./images/43.jpg";
import Single44 from "./images/44.jpg";
import Single45 from "./images/45.jpg";
import Single46 from "./images/46.jpg";
import Single47 from "./images/47.jpg";
import Single48 from "./images/48.jpg";
import Single49 from "./images/49.jpg";
import Single50 from "./images/50.jpg";
import Single51 from "./images/51.jpg";
import Single52 from "./images/52.jpg";
import Single53 from "./images/53.jpg";
import Single54 from "./images/54.jpg";
import Single55 from "./images/55.jpg";
import Single56 from "./images/56.jpg";
import Single57 from "./images/57.jpg";
import Single58 from "./images/58.jpg";
import Single59 from "./images/59.jpg";
import Single60 from "./images/60.jpg";
import Single61 from "./images/61.jpg";
import Single62 from "./images/62.jpg";
import Single63 from "./images/63.jpg";
import Single64 from "./images/64.jpg";
import Single65 from "./images/65.jpg";
import Single66 from "./images/66.jpg";
import Single67 from "./images/67.jpg";
import Single68 from "./images/68.jpg";
import Single69 from "./images/69.jpg";
import Single70 from "./images/70.jpg";
import Single71 from "./images/71.jpg";
import Single72 from "./images/72.jpg";
import PageWrapper from "../../PageWrapper";
import Workshop from "../Workshop/Workshop";
import Photography from "../Photography/Photography";

const LazyImage = ({
  image,
  isMobile,
  setCurrentPhoto,
  setGalleryOpen,
  height,
  width,
}) => {
  let shrinkPercentage = Math.floor(
    (isMobile ? window.innerWidth / width : 750 / image.width) * 100
  );
  let realHeight = parseInt(image.height * (shrinkPercentage / 100));
  return (
    <LazyLoadImage
      onClick={() => {
        setCurrentPhoto();
        setGalleryOpen(true);
      }}
      alt={image.alt}
      effect="blur"
      src={image.src}
      style={{
        paddingBottom: 8,
        width: !isMobile ? "750px" : "100%",
        height: realHeight,
        paddingRight: !isMobile && "calc(50% - 375px)",
        paddingLeft: !isMobile && "calc(50% - 375px)",
        cursor: "pointer",
      }}
    />
  );
};
const images = [
  Single1,
  Single2,
  Single3,
  Single4,
  Single5,
  Single6,
  Single7,
  Single8,
  Single9,
  Single10,
  Single11,
  Single12,
  Single13,
  Single14,
  Single15,
  Single16,
  Single17,
  Single18,
  Single19,
  Single20,
  Single21,
  Single22,
  Single23,
  Single24,
  Single25,
  Single26,
  Single27,
  Single28,
  Single29,
  Single30,
  Single31,
  Single32,
  Single33,
  Single34,
  Single35,
  Single36,
  Single37,
  Single38,
  Single39,
  Single40,
  Single41,
  Single42,
  Single43,
  Single44,
  Single45,
  Single46,
  Single47,
  Single48,
  Single49,
  Single50,
  Single51,
  Single52,
  Single53,
  Single54,
  Single55,
  Single56,
  Single57,
  Single58,
  Single59,
  Single60,
  Single61,
  Single62,
  Single63,
  Single64,
  Single65,
  Single66,
  Single67,
  Single68,
  Single69,
  Single70,
  Single71,
  Single72,
];
const Homepage = (props) => {
  const [cvCollapsed, setCvColappsed] = React.useState(false);
  const [textScaled, setTextScaled] = useState(false);
  const [secondTextVisible, setSecondTextVisible] = useState(false);
  const [currentPhoto, setCurrentPhoto] = useState(0);
  const [galleryOpen, setGalleryOpen] = useState(false);
  const [scrollY, setScrollY] = useState(window.scrollY);
  const nameRef = React.createRef();
  useEffect(() => {
    setTimeout(() => {
      if (window.location.pathname === "/cv") {
        props.cvRef &&
          props.cvRef.current &&
          props.cvRef.current.scrollIntoView();
      } else if (window.location.pathname === "/bio") {
        props.bioRef &&
          props.bioRef.current &&
          props.bioRef.current.scrollIntoView();
      } else if (window.location.pathname === "/contact") {
        props.contactRef &&
          props.contactRef.current &&
          props.contactRef.current.scrollIntoView();
      } else if (window.location.pathname === "/video") {
        props.videoRef &&
          props.videoRef.current &&
          props.videoRef.current.scrollIntoView();
      } else if (window.location.pathname === "/reviews") {
        props.reviewsRef &&
          props.reviewsRef.current &&
          props.reviewsRef.current.scrollIntoView();
      } else if (window.location.pathname === "/workshop") {
        props.workshopRef &&
          props.workshopRef.current &&
          props.workshopRef.current.scrollIntoView();
      } else if (window.location.pathname === "/photography") {
        props.photographyRef &&
          props.photographyRef.current &&
          props.photographyRef.current.scrollIntoView();
      }
      setTimeout(() => setTextScaled(true), 1300);
      setTimeout(() => setSecondTextVisible(true), 1600);
    }, 200);
  }, []);
  React.useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });
  const galleryRef = React.createRef();
  const isMobile = () => {
    if (props.width === "xs" || props.width === "sm") {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div ref={galleryRef}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{isMobile() ? "Davit Baroyan" : "Galeria"}</title>
        <link rel="canonical" href="https://davitbaroyan.com/gallery" />
      </Helmet>
      <div
        style={{
          paddingTop: isMobile() && "32px",
          color: "#fff",
          overflow: "auto",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {isMobile() && (
          <Fade in={true} timeout={5000}>
            <div
              ref={nameRef}
              style={{
                color: "#fff",
                display: "flex",
                //fontSize: "3vw",
                position: "relative",
                width: "100%",
                height: window.innerHeight - 53,
                //  minHeight: 200,
                top: "calc(50% - 100px)",
                justifyContent: "center",
                overflow: "auto",
                alignItems: "center",
                transition: "font 3s ease",
              }}
            >
              <ListItemText
                primary={
                  <Typography
                    variant={"h3"}
                    style={{
                      //fontSize: "3vw",
                      fontWeight: 200,
                      fontFamily: "inherit",
                      //marginLeft: 270,
                      marginTop: 0,
                      marginBottom: 0,
                      textAlign: "center",
                      transition: "all 0.7s ease",
                      transform: textScaled ? "scale(1)" : "scale(0.9)",
                    }}
                  >
                    DAVIT BAROYAN
                  </Typography>
                }
                // secondary={
                //   secondTextVisible && (
                //     <Fade in={secondTextVisible}>
                //       <Typography
                //         variant="h4"
                //         style={{
                //           fontWeight: 200,
                //           fontFamily: "inherit",
                //           //  marginLeft: 270,
                //           marginTop: 0,
                //           marginBottom: 0,
                //           color: "gray",
                //           textAlign: "center",
                //         }}
                //       >
                //         Aktor teatru ZAR
                //       </Typography>
                //     </Fade>
                //   )
                // }
              />
            </div>
          </Fade>
        )}

        <LazyImage
          isMobile={isMobile()}
          image={{ src: Image18, alt: "photo" }}
          setGalleryOpen={setGalleryOpen}
          setCurrentPhoto={() => setCurrentPhoto(69)}
          width={1200}
          height={1463}
        />

        <LazyImage
          isMobile={isMobile()}
          image={{ src: Image19, alt: "photo" }}
          setGalleryOpen={setGalleryOpen}
          setCurrentPhoto={() => setCurrentPhoto(66)}
          width={1200}
          height={1463}
        />

        <LazyImage
          isMobile={isMobile()}
          image={{ src: Image20, alt: "photo" }}
          setGalleryOpen={setGalleryOpen}
          setCurrentPhoto={() => setCurrentPhoto(62)}
          width={1200}
          height={1463}
        />

        <LazyImage
          isMobile={isMobile()}
          image={{ src: Image21, alt: "photo" }}
          setGalleryOpen={setGalleryOpen}
          setCurrentPhoto={() => setCurrentPhoto(59)}
          width={1200}
          height={1463}
        />

        <LazyImage
          isMobile={isMobile()}
          image={{ src: Image12, alt: "photo" }}
          setGalleryOpen={setGalleryOpen}
          setCurrentPhoto={() => setCurrentPhoto(55)}
          width={1200}
          height={1463}
        />

        <LazyImage
          isMobile={isMobile()}
          image={{ src: Image13, alt: "photo" }}
          setGalleryOpen={setGalleryOpen}
          setCurrentPhoto={() => setCurrentPhoto(50)}
          width={1200}
          height={1463}
        />
        <LazyImage
          isMobile={isMobile()}
          image={{ src: Image14, alt: "photo" }}
          setGalleryOpen={setGalleryOpen}
          setCurrentPhoto={() => setCurrentPhoto(47)}
          width={1200}
          height={1463}
        />
        <LazyImage
          isMobile={isMobile()}
          image={{ src: Image15, alt: "photo" }}
          setGalleryOpen={setGalleryOpen}
          setCurrentPhoto={() => setCurrentPhoto(44)}
          width={1200}
          height={1463}
        />
        <LazyImage
          isMobile={isMobile()}
          image={{ src: Image16, alt: "photo" }}
          setGalleryOpen={setGalleryOpen}
          setCurrentPhoto={() => setCurrentPhoto(39)}
          width={1200}
          height={1463}
        />
        <LazyImage
          isMobile={isMobile()}
          image={{ src: Image17, alt: "photo" }}
          setGalleryOpen={setGalleryOpen}
          setCurrentPhoto={() => setCurrentPhoto(35)}
          width={1200}
          height={1463}
        />

        <LazyImage
          isMobile={isMobile()}
          image={{ src: Image11, alt: "photo" }}
          setGalleryOpen={setGalleryOpen}
          setCurrentPhoto={() => setCurrentPhoto(31)}
          width={1200}
          height={1463}
        />

        {isMobile() && (
          <PageWrapper>
            <div ref={props.cvRef} style={{ marginTop: 32, marginBottom: 32 }}>
              <Typography
                variant="h4"
                style={{
                  fontWeight: 200,
                  fontFamily: "inherit",
                  marginTop: 0,
                  marginBottom: 0,
                  color: "gray",
                  textAlign: "center",
                }}
              >
                CV
              </Typography>

              <Cv />
            </div>
          </PageWrapper>
        )}
        {/* <LazyImage
          isMobile={isMobile()}
          image={{ src: Image3, alt: "photo" }}
          setGalleryOpen={setGalleryOpen}
          setCurrentPhoto={() => setCurrentPhoto(26)}
          width={1200}
          height={2196}
        /> */}
        {isMobile() && (
          <PageWrapper>
            <div ref={props.bioRef}>
              <Typography
                variant="h4"
                style={{
                  fontWeight: 200,
                  fontFamily: "inherit",
                  marginTop: 0,
                  marginBottom: 0,
                  color: "gray",
                  textAlign: "center",
                }}
              >
                BIO
              </Typography>

              <Bio />
            </div>
          </PageWrapper>
        )}

        {/* <LazyImage
          isMobile={isMobile()}
          image={{ src: Image6, alt: "photo" }}
          setGalleryOpen={setGalleryOpen}
          setCurrentPhoto={() => setCurrentPhoto(24)}
          width={1200}
          height={939}
        /> */}
        {isMobile() && (
          <PageWrapper>
            <div style={{ paddingBottom: 32 }} ref={props.videoRef}>
              <Typography
                variant="h4"
                style={{
                  fontWeight: 200,
                  fontFamily: "inherit",
                  marginTop: 0,
                  marginBottom: 0,
                  color: "gray",
                  textAlign: "center",
                }}
              >
                WIDEO
              </Typography>

              <Video />
            </div>
          </PageWrapper>
        )}
        {isMobile() && (
          <PageWrapper>
            <div style={{ paddingBottom: 32 }} ref={props.reviewsRef}>
              <Typography
                variant="h4"
                style={{
                  fontWeight: 200,
                  fontFamily: "inherit",
                  marginTop: 0,
                  marginBottom: 0,
                  color: "gray",
                  textAlign: "center",
                }}
              >
                RECENZJE I WYWIADY
              </Typography>

              <Reviews />
            </div>
          </PageWrapper>
        )}
        {isMobile() && (
          <PageWrapper>
            <div style={{ paddingBottom: 32 }} ref={props.workshopRef}>
              <Typography
                variant="h4"
                style={{
                  fontWeight: 200,
                  fontFamily: "inherit",
                  marginTop: 0,
                  marginBottom: 0,
                  color: "gray",
                  textAlign: "center",
                }}
              >
                OFERTA WARSZTATOWA
              </Typography>

              <Workshop />
            </div>
          </PageWrapper>
        )}
        {isMobile() && (
          <PageWrapper>
            <div style={{ paddingBottom: 32 }} ref={props.photographyRef}>
              <Typography
                variant="h4"
                style={{
                  fontWeight: 200,
                  fontFamily: "inherit",
                  marginTop: 0,
                  marginBottom: 0,
                  color: "gray",
                  textAlign: "center",
                }}
              >
                FOTOGRAFIA WARSZTATOWA
              </Typography>

              <Photography />
            </div>
          </PageWrapper>
        )}
        {isMobile() && (
          <div ref={props.contactRef}>
            <Typography
              variant="h4"
              style={{
                fontWeight: 200,
                fontFamily: "inherit",
                marginTop: 0,
                marginBottom: 0,
                color: "gray",
                textAlign: "center",
              }}
            >
              KONTAKT
            </Typography>

            <Contact />
          </div>
        )}

        {/* <LazyImage
          isMobile={isMobile()}
          image={{ src: Image7, alt: "photo" }}
          setGalleryOpen={setGalleryOpen}
          setCurrentPhoto={() => setCurrentPhoto(21)}
          width={1200}
          height={755}
        />

        <LazyImage
          isMobile={isMobile()}
          image={{ src: Image1, alt: "photo" }}
          setGalleryOpen={setGalleryOpen}
          setCurrentPhoto={() => setCurrentPhoto(18)}
          width={1200}
          height={1290}
        />
        <LazyImage
          isMobile={isMobile()}
          image={{ src: Image5, alt: "photo" }}
          setGalleryOpen={setGalleryOpen}
          setCurrentPhoto={() => setCurrentPhoto(16)}
          width={1200}
          height={400}
        />
        <LazyImage
          isMobile={isMobile()}
          image={{ src: Image2, alt: "photo" }}
          setGalleryOpen={setGalleryOpen}
          setCurrentPhoto={() => setCurrentPhoto(14)}
          width={1200}
          height={1290}
        />
        <LazyImage
          isMobile={isMobile()}
          image={{ src: Image8, alt: "photo" }}
          setGalleryOpen={setGalleryOpen}
          setCurrentPhoto={() => setCurrentPhoto(11)}
          width={1200}
          height={1121}
        />
        <LazyImage
          isMobile={isMobile()}
          image={{ src: Image9, alt: "photo" }}
          setGalleryOpen={setGalleryOpen}
          setCurrentPhoto={() => setCurrentPhoto(7)}
          width={1200}
          height={957}
        />
        <LazyImage
          isMobile={isMobile()}
          image={{ src: Image10, alt: "photo" }}
          setGalleryOpen={setGalleryOpen}
          setCurrentPhoto={() => setCurrentPhoto(3)}
          width={1200}
          height={846}
        />
        <LazyImage
          isMobile={isMobile()}
          image={{ src: Image4, alt: "photo" }}
          setGalleryOpen={setGalleryOpen}
          setCurrentPhoto={() => setCurrentPhoto(0)}
          width={1200}
          height={1260}
        /> */}
      </div>

      <Slide direction="up" in={scrollY !== 0}>
        <IconButton
          style={{ position: "fixed", bottom: 12, right: "calc(50% - 24px)" }}
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
        >
          <KeyboardArrowUpIcon
            style={{
              fontSize: 40,
              color: "rgba(255,255,255,0.7)",
            }}
          />
        </IconButton>
      </Slide>
      <Slide direction="up" in={scrollY === 0}>
        <IconButton
          style={{ position: "fixed", bottom: 12, right: "calc(50% - 24px)" }}
          onClick={() => {
            props.cvRef &&
              props.cvRef.current &&
              props.cvRef.current.scrollIntoView({
                behavior: "smooth",
              });
          }}
        >
          <KeyboardArrowDownIcon
            style={{
              fontSize: 40,
              color: "rgba(255,255,255,0.7)",
            }}
          />
        </IconButton>
      </Slide>
      {galleryOpen && (
        <Lightbox
          mainSrc={images[currentPhoto]}
          nextSrc={images[(currentPhoto + 1) % images.length]}
          prevSrc={images[(currentPhoto + images.length - 1) % images.length]}
          onCloseRequest={() => setGalleryOpen(false)}
          onMovePrevRequest={() =>
            setCurrentPhoto((currentPhoto + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setCurrentPhoto((currentPhoto + 1) % images.length)
          }
        />
      )}
    </div>
  );
};

export default withWidth()(Homepage);
