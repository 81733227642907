import React from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Fade from "@material-ui/core/Fade";
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles({
  root: {
    maxWidth: "15%",
  },
  card: {
    paddingBottom:"16px"
  },
  edge: {
    borderRadius: '0 !important',
    backgroundColor: 'black',
    position: 'relative',
  },
  container: {
    display: "flex",
    justifyContent: "center",
    color: "#fff",
    flexWrap: "wrap",
    paddingLeft: '2%',
    paddingRight: '2%',
    paddingBottom: '1.5%',
    paddingTop: '2%',
  },
  typography: {
     color:"#FEFEFE"
  },
  button: {
    position: 'absolute',
    bottom: "0px"
  },

});

export default function ImgMediaCard() {
  const classes = useStyles();

  return (
    <div
    className={classes.container}
    style={{ paddingTop:  "3%" }}
  >
    <Grid container>
    <Grid
        item
        xs={12}
        sm={4}
        className={classes.container}
      >
    <Fade in={true} timeout={3000}>
      <Card className={classes.edge}>
        <CardMedia
          component="img"
          alt="recenzja1"
          height="320"
          image={require ("./trociny.jpg")}
          title="trociny"
        />
        <CardContent>
            <div
              className={classes.typography}
             >
          <Typography gutterBottom variant="h5" component="h2">
          Macica przejścia podziemnego
          </Typography>
          <Typography variant="body2" color="white" component="p">
            L„Trociny” – monodram Davita Baroyana w Instytucie im. Jerzego Grotowskiego we Wrocławiu – pisze Jarosław Klebaniuk.
          </Typography>
            </div>
        </CardContent>
      <CardActions style={{  justifyContent:'flex-end' }}>
         <Button
            className={classes.button}
            color="#FFFFFF"
            type="button"
            onClick={(e) => {
            e.preventDefault();
            window.location.href='https://teatrdlawszystkich.eu/macica-przejscia-podziemnego/';
            }}>
            <div
              className={classes.typography}
             >
          <Typography gutterBottom variant="button" component="h2">
          Czytaj dalej
          </Typography>
          </div>
        </Button>
      </CardActions>
    </Card>
    </Fade>
    </Grid>
    <Grid
        item
        xs={12}
        sm={4}
        className={classes.container}
      >
    <Fade in={true} timeout={3000}>
      <Card className={classes.edge}>
        <CardMedia
          component="img"
          alt="recenzja2"
          height="320"
          image={require ("./teatralny.jpg")}
          title="teatralny"
        />
        <CardContent>
           <div
              className={classes.typography}
             >
          <Typography gutterBottom variant="h5" component="h2">
          Trzewia manekinów
          </Typography>
          <Typography variant="body2" color="white" component="p">
          „Tysiąc wyperfumowanych manekinów” – tak bohater monodramu Trociny opisuje publiczność filharmonii, wśród której znalazł się za namową swojej dziewczyny.
          </Typography>
          </div>
        </CardContent>
      <CardActions style={{  justifyContent:'flex-end' }}>
        <Button
             className={classes.button}
            type="button"
            onClick={(e) => {
            e.preventDefault();
            window.location.href='https://teatralny.pl/recenzje/trzewia-manekinow,2696.html';
            }}>
             <div
              className={classes.typography}
             >
          <Typography gutterBottom variant="button" component="h2">
          Czytaj dalej
          </Typography>
          </div>
        </Button>
      </CardActions>
    </Card>
    </Fade>
    </Grid>
    <Grid
        item
        xs={12}
        sm={4}
        className={classes.container}
      >
    <Fade in={true} timeout={3000}>
      <Card className={classes.edge}>
        <CardMedia
          component="img"
          alt="recenzja3"
          height="320"
          image={require ("./eteatr.png")}
          title="eteatr"
        />
        <CardContent >
            <div
              className={classes.typography}
             >
          <Typography gutterBottom variant="h5" component="h2">
          Wrocław. "Trociny" w Instytucie Grotowskiego
          </Typography>
          <Typography variant="body2" color="white" component="p">
          19 marca odbędzie się w Instytucie Grotowskiego premiera monodramu "Trociny" Davita Baroyana na podstawie powieści Krzysztofa Vargi.
          </Typography>
          </div>
        </CardContent>
      <CardActions style={{  justifyContent:'flex-end' }}>
            <Button
            className={classes.button}
             type="button"
             onClick={(e) => {
             e.preventDefault();
             window.location.href='https://e-teatr.pl/wroclaw-trociny-w-instytucie-grotowskiego-a265722';
             }}>
                <div
              className={classes.typography}
             >
          <Typography gutterBottom variant="button" component="h2">
          Czytaj dalej
          </Typography>
          </div>
            </Button>
      </CardActions>
    </Card>
    </Fade>
    </Grid>
</Grid>


<Grid container>
    <Grid
        item
        xs={12}
        sm={4}
        className={classes.container}
      >
    <Fade in={true} timeout={3000}>
      <Card className={classes.edge}>
        <CardMedia
          component="img"
          alt="recenzja4"
          height="320"
          image={require ("./armine.jpg")}
          title="armine"
        />
        <CardContent>
            <div
              className={classes.typography}
             >
          <Typography gutterBottom variant="h5" component="h2">
          Armine, Sister
          </Typography>
          <Typography variant="body2" color="white" component="p">
          "W pierwotnym zamierzeniu spektakl miał być seansem, w którym to nie my wołamy tych, którzy odeszli, lecz duchy zmarłych wołają o unaocznienie, odczytanie śladu."
          </Typography>
            </div>
        </CardContent>
      <CardActions style={{  justifyContent:'flex-end' }}>
         <Button
            className={classes.button}
            color="#FFFFFF"
            type="button"
            onClick={(e) => {
            e.preventDefault();
            window.location.href='http://www.teatrzar.pl/armine-sister/armine-sister?fbclid=IwAR0Wp8iphV-7PGLpClW26tIM_7BHF43Eqlv1G7GfO7Uu5aVSsVVKZnei1aM#artykuly';
            }}>
            <div
              className={classes.typography}
             >
          <Typography gutterBottom variant="button" component="h2">
          Czytaj dalej
          </Typography>
          </div>
        </Button>
      </CardActions>
    </Card>
    </Fade>
    </Grid>
    <Grid
        item
        xs={12}
        sm={4}
        className={classes.container}
      >
    <Fade in={true} timeout={3000}>
      <Card className={classes.edge}>
        <CardMedia
          component="img"
          alt="recenzja2"
          height="320"
          image={require ("./muzykaRWK.jpg")}
          title="teatralny"
        />
        <CardContent>
           <div
              className={classes.typography}
             >
          <Typography gutterBottom variant="h5" component="h2">
          Muzyka w RWK: Davit Baroyan, Kapela Bożków i Bardziej Plus
          </Typography>
          <Typography variant="body2" color="white" component="p">
          „Zaczęliśmy od spotkania z Davitem Baroyanem. Związany z Wrocławiem aktor Teatru ZAR wydał niedawno debiutancki hip-hopowy album pod tytułem "Balans"."
          </Typography>
          </div>
        </CardContent>
      <CardActions style={{  justifyContent:'flex-end' }}>
        <Button
             className={classes.button}
            type="button"
            onClick={(e) => {
            e.preventDefault();
            window.location.href='https://www.radiowroclaw.pl/articles/view/97599/Muzyka-w-RWK-27-2020?fbclid=IwAR3y7Hy1AEdRDb5DP54dCVH3lk1obB5bNKkrrBZX86z-Sqx8-FKygwJ-oxs';
            }}>
             <div
              className={classes.typography}
             >
          <Typography gutterBottom variant="button" component="h2">
          Czytaj dalej
          </Typography>
          </div>
        </Button>
      </CardActions>
    </Card>
    </Fade>
    </Grid>
    
</Grid>
</div>

  );
}