import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import { Switch, Route, Link } from "react-router-dom";
import withWidth from "@material-ui/core/withWidth";

const useStyles = makeStyles({
  ul: {
    listStyleType: "none",
    margin: 0,
    padding: 0,
    overflow: "hidden",
  },
  li: {
    float: "left",
    "&:hover": {
      fontWeight: "bold",
    },
    display: "block",
    boxShadow: "0px 0px 84px -24px rgba(0,0,0,0.75)",
  },
  link: {
    display: "block",
    color: "white",
    textAlign: "center",
    padding: 16,
    textDecoration: "none",
    zIndex: "-1",
  },
  linkMobile: {
    display: "block",
    color: "white",
    textAlign: "center",
    padding: 16,
    textDecoration: "none",
    zIndex: "-1",
  },
  galleryLink: {
    display: "block",
    color: "white",
    textAlign: "center",
    padding: 16,
    textDecoration: "none",
    zIndex: "-1",
  },
  galleryLinkMobile: {
    display: "block",
    color: "white",
    textAlign: "center",
    padding: 12,
    textDecoration: "none",
    zIndex: "-1",
    fontSize: "8.5px",
  },
  menu: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  menuMobile: {
    top: 0,
    position: "fixed",
    zIndex: 1000,
    backgroundColor: "white",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
});

const Menu = (props) => {
  const [menuVisible, setMenuVisible] = React.useState(true);
  const [current, setCurrent] = React.useState(true);
  const [scrollY, setScrollY] = React.useState(0);
  const { pathname } = props;
  var linkClass = null;
  const classes = useStyles();
  const isMobile = () => {
    if (props.width === "xs" || props.width === "sm") {
      return true;
    } else {
      return false;
    }
  };
  if (pathname === "/gallery" || isMobile()) {
    linkClass = isMobile() ? classes.galleryLinkMobile : classes.galleryLink;
  } else {
    linkClass = isMobile() ? classes.linkMobile : classes.link;
  }
  React.useEffect(() => {
    setCurrent(window.location.pathname);
    const handleScroll = () => {
      setScrollY(window.scrollY);
      setMenuVisible(window.scrollY > scrollY ? false : true);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <Slide direction="top" in={isMobile() ? menuVisible : true}>
      <nav
        translate="no"
        className={isMobile() ? classes.menuMobile : classes.menu}
      >
        <ul className={classes.ul}>
          <Switch>
            <Route exact path="/gallery">
              {!isMobile() && (
                <React.Fragment>
                  <li className={classes.li}>
                    <Link
                      className={
                        isMobile()
                          ? classes.galleryLinkMobile
                          : classes.galleryLink
                      }
                      style={{
                        color: isMobile() && "#847d7d",
                        fontWeight: current === "/" && 700,
                      }}
                      to={!isMobile() && "/"}
                      onClick={() => setCurrent("/")}
                    >
                      START
                    </Link>
                  </li>
                  <li className={classes.li}>
                    <Link
                      className={
                        isMobile()
                          ? classes.galleryLinkMobile
                          : classes.galleryLink
                      }
                      style={{
                        color: isMobile() && "#847d7d",
                        fontWeight: current === "/gallery" && 700,
                      }}
                      to={!isMobile() && "/gallery"}
                      onClick={() => setCurrent("/gallery")}
                    >
                      GALERIA
                    </Link>
                  </li>
                </React.Fragment>
              )}
              {/* <li className={ isMobile()?classes.liMobile:classes.li}><Link  className={ isMobile()?classes.linkMobile:classes.link} to="/video" >WIDEO</Link></li> */}
              <li className={classes.li}>
                <Link
                  className={
                    isMobile() ? classes.galleryLinkMobile : classes.galleryLink
                  }
                  style={{
                    color: isMobile() && "#847d7d",
                    fontWeight: current === "/cv" && 700,
                  }}
                  onClick={() => {
                    props.cvRef &&
                      props.cvRef.current &&
                      props.cvRef.current.scrollIntoView({
                        behavior: "smooth",
                      });
                    setCurrent("/cv");
                  }}
                  to={!isMobile() && "/cv"}
                >
                  CV
                </Link>
              </li>
              <li className={classes.li}>
                <Link
                  onClick={() => {
                    props.bioRef &&
                      props.bioRef.current &&
                      props.bioRef.current.scrollIntoView({
                        behavior: "smooth",
                      });
                    setCurrent("/cv");
                  }}
                  className={
                    isMobile() ? classes.galleryLinkMobile : classes.galleryLink
                  }
                  style={{
                    color: isMobile() && "#847d7d",
                    fontWeight: current === "/bio" && 700,
                  }}
                  to={!isMobile() && "/bio"}
                >
                  BIO
                </Link>
              </li>
              <li className={classes.li}>
                <Link
                  onClick={() => {
                    props.videoRef &&
                      props.videoRef.current &&
                      props.videoRef.current.scrollIntoView({
                        behavior: "smooth",
                      });
                    setCurrent("/video");
                  }}
                  className={
                    isMobile() ? classes.galleryLinkMobile : classes.galleryLink
                  }
                  style={{
                    color: isMobile() && "#847d7d",
                    fontWeight: current === "/video" && 700,
                  }}
                  to={!isMobile() && "/video"}
                >
                  WIDEO
                </Link>
              </li>
              <li className={classes.li}>
                <Link
                  onClick={() => {
                    props.reviewsRef &&
                      props.reviewsRef.current &&
                      props.reviewsRef.current.scrollIntoView({
                        behavior: "smooth",
                      });
                    setCurrent("/reviews");
                  }}
                  className={
                    isMobile() ? classes.galleryLinkMobile : classes.galleryLink
                  }
                  style={{
                    color: isMobile() && "#847d7d",
                    fontWeight: current === "/reviews" && 700,
                  }}
                  to={!isMobile() && "/reviews"}
                >
                  RECENZJE I WYWIADY
                </Link>
              </li>
              <li className={classes.li}>
                <Link
                  onClick={() => {
                    props.workshopRef &&
                      props.workshopRef.current &&
                      props.workshopRef.current.scrollIntoView({
                        behavior: "smooth",
                      });
                    setCurrent("/workshop");
                  }}
                  className={
                    isMobile()
                      ? classes.galleryLinkMobile
                      : classes.galleryLink
                  }
                  style={{
                    color: isMobile() && "#847d7d",
                    fontWeight: current === "/workshop" && 700,
                  }}
                  to={!isMobile() && "/workshop"}
                >
                  OFERTA WARSZTATOWA
                </Link>
              </li>
              <li className={classes.li}>
                <Link
                  onClick={() => {
                    props.photographyRef &&
                      props.photographyRef.current &&
                      props.photographyRef.current.scrollIntoView({
                        behavior: "smooth",
                      });
                    setCurrent("/photography");
                  }}
                  className={
                    isMobile()
                      ? classes.galleryLinkMobile
                      : classes.galleryLink
                  }
                  style={{
                    color: isMobile() && "#847d7d",
                    fontWeight: current === "/photography" && 700,
                  }}
                  to={!isMobile() && "/photography"}
                >
                  FOTOGRAFIA WARSZTATOWA
                </Link>
              </li>
              {/* <li className={classes.li}>
                <a
                  className={linkClass}
                  style={{ color: isMobile() && "#847d7d" }}
                  href="https://davbaroyan.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ALBUM
                </a>
              </li> */}
              <li className={classes.li}>
                <Link
                  onClick={() => {
                    props.contactRef &&
                      props.contactRef.current &&
                      props.contactRef.current.scrollIntoView({
                        behavior: "smooth",
                      });
                    setCurrent("/contact");
                  }}
                  className={
                    isMobile() ? classes.galleryLinkMobile : classes.galleryLink
                  }
                  style={{
                    color: isMobile() && "#847d7d",
                    fontWeight: current === "/contact" && 700,
                  }}
                  to={!isMobile() && "/contact"}
                >
                  KONTAKT
                </Link>
              </li>
            </Route>
            <Route
              exact
              path={[
                "/cv",
                "/gallery",
                "/contact",
                "/",
                "/bio",
                "/video",
                "/reviews",
                "/workshop",
                "/photography"
              ]}
            >
              {!isMobile() && (
                <React.Fragment>
                  <li className={classes.li}>
                    <Link
                      className={linkClass}
                      style={{
                        color: isMobile() && "#847d7d",
                        fontWeight: current === "/" && 700,
                      }}
                      to={!isMobile() && "/"}
                      onClick={() => setCurrent("/")}
                    >
                      START
                    </Link>
                  </li>
                  <li className={classes.li}>
                    <Link
                      className={linkClass}
                      style={{
                        color: isMobile() && "#847d7d",
                        fontWeight: current === "/gallery" && 700,
                      }}
                      to={!isMobile() && "/gallery"}
                      onClick={() => setCurrent("/gallery")}
                    >
                      GALERIA
                    </Link>
                  </li>
                </React.Fragment>
              )}
              {/* <li className={ isMobile()?classes.liMobile:classes.li}><Link  className={ isMobile()?classes.linkMobile:classes.link} to="/video" >WIDEO</Link></li> */}
              <li className={classes.li}>
                <Link
                  onClick={() => {
                    props.cvRef &&
                      props.cvRef.current &&
                      props.cvRef.current.scrollIntoView({
                        behavior: "smooth",
                      });
                    setCurrent("/cv");
                  }}
                  className={linkClass}
                  style={{
                    color: isMobile() && "#847d7d",
                    fontWeight: current === "/cv" && 700,
                  }}
                  to={!isMobile() && "/cv"}
                >
                  CV
                </Link>
              </li>
              <li className={classes.li}>
                <Link
                  onClick={() => {
                    props.bioRef &&
                      props.bioRef.current &&
                      props.bioRef.current.scrollIntoView({
                        behavior: "smooth",
                      });
                    setCurrent("/bio");
                  }}
                  className={linkClass}
                  style={{
                    color: isMobile() && "#847d7d",
                    fontWeight: current === "/bio" && 700,
                  }}
                  to={!isMobile() && "/bio"}
                >
                  BIO
                </Link>
              </li>
              <li className={classes.li}>
                <Link
                  onClick={() => {
                    props.videoRef &&
                      props.videoRef.current &&
                      props.videoRef.current.scrollIntoView({
                        behavior: "smooth",
                      });
                    setCurrent("/video");
                  }}
                  className={linkClass}
                  style={{
                    color: isMobile() && "#847d7d",
                    fontWeight: current === "/video" && 700,
                  }}
                  to={!isMobile() && "/video"}
                >
                  WIDEO
                </Link>
              </li>
              <li className={classes.li}>
                <Link
                  onClick={() => {
                    props.reviewsRef &&
                      props.reviewsRef.current &&
                      props.reviewsRef.current.scrollIntoView({
                        behavior: "smooth",
                      });
                    setCurrent("/reviews");
                  }}
                  className={linkClass}
                  style={{
                    color: isMobile() && "#847d7d",
                    fontWeight: current === "/reviews" && 700,
                  }}
                  to={!isMobile() && "/reviews"}
                >
                  RECENZJE I WYWIADY
                </Link>
              </li>
              <li className={classes.li}>
                <Link
                  onClick={() => {
                    props.workshopRef &&
                      props.workshopRef.current &&
                      props.workshopRef.current.scrollIntoView({
                        behavior: "smooth",
                      });
                    setCurrent("/workshop");
                  }}
                  className={linkClass}
                  style={{
                    color: isMobile() && "#847d7d",
                    fontWeight: current === "/workshop" && 700,
                  }}
                  to={!isMobile() && "/workshop"}
                >
                  OFERTA WARSZTATOWA
                </Link>
              </li>
              <li className={classes.li}>
                <Link
                  onClick={() => {
                    props.photographyRef &&
                      props.photographyRef.current &&
                      props.photographyRef.current.scrollIntoView({
                        behavior: "smooth",
                      });
                    setCurrent("/photography");
                  }}
                  className={linkClass}
                  style={{
                    color: isMobile() && "#847d7d",
                    fontWeight: current === "/photography" && 700,
                  }}
                  to={!isMobile() && "/photography"}
                >
                  FOTOGRAFIA WARSZTATOWA
                </Link>
              </li>
              {/* <li className={classes.li}>
                <a
                  className={linkClass}
                  style={{ color: isMobile() && "#847d7d" }}
                  href="https://davbaroyan.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ALBUM
                </a>
              </li> */}
              <li className={classes.li}>
                <Link
                  onClick={() => {
                    props.contactRef &&
                      props.contactRef.current &&
                      props.contactRef.current.scrollIntoView({
                        behavior: "smooth",
                      });
                    setCurrent("/contact");
                  }}
                  className={linkClass}
                  style={{
                    color: isMobile() && "#847d7d",
                    fontWeight: current === "/contact" && 700,
                  }}
                  to={!isMobile() && "/contact"}
                >
                  KONTAKT
                </Link>
              </li>
            </Route>
          </Switch>
        </ul>
      </nav>
    </Slide>
  );
};

export default withWidth()(Menu);
