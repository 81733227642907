export function experience() {
  return [
    {
      text: "",
      date: "2014 - do teraz.",
      category: {
        tag: "Teatr ZAR, Wrocław",

        color: "lightgray"
      }
    },
    {
      text: "Gościnnie",
      date: "2010 - 2014r.",
      category: {
        tag: "Teatr BRAMA, Goleniów",

        color: "lightgray"
      }
    },
    {
      text: "",
      date: "2007 - 2012r.",
      category: {
        tag: "Teatr Piktogram, Opole",

        color: "lightgray"
      }
    }
  ];
}

export function roles() {
  return [
    {
      text: "reż. Xawery Żuławski",
      date: "2024",
      category: {
        tag: "Serial 'Odwliż', MAX",

        color: "lightgray"
      }
    },
    {
      text: "reż. Jan Holoubek",
      date: "2023",
      category: {
        tag: "Serial 'Rojst. Millenium', NETFLIX",

        color: "lightgray"
      }
    },
    {
      text: "reż. Agnieszka Holland",
      date: "2023",
      category: {
        tag: "Zielona Granica",

        color: "lightgray"
      }
    },
    {
      text: "reż. Hiroshi Koike",
      date: "2023",
      category: {
        tag: "KOSMOS, W. Gombrowicz",

        color: "lightgray"
      }
    },
    {
      text: "",
      date: "2022",
      category: {
        tag: "Serial 'Bunt', TVN",

        color: "lightgray"
      }
    },
    {
      text: "reż. Jarosław Fret (Teatr ZAR)",
      date: "2021 - do teraz",
      category: {
        tag: "Archipelag Tadeusza Różewicza: Złowione",

        color: "lightgray"
      }
    },
    {
      text: "reż. Jarosław Fret (Teatr ZAR)",
      date: "2019 - do teraz",
      category: {
        tag: "ANHELLI. SKOWYT",

        color: "lightgray"
      }
    },
    {
      text: "(monodram Davita Baroyana, reż. Jarosław Fret)",
      date: "2019 - do teraz",
      category: {
        tag: "TROCINY",

        color: "lightgray"
      }
    },
    {
      text: "reż. Teo Dumski (Cloud Teater)",
      date: "2017 - 2019r.",
      category: {
        tag: "TECHNOLOGIA JEST ISTOTĄ",

        color: "lightgray"
      }
    },
    {
      text:
        "Indywidualny projekt Davita Baroyana, Dominika Smaruja, Huberta Kożuchowskiego w ramach Akademii Teatru Alternatywnego z ramienia Ministerstwa Kultury i Dziedzictwa Narodowego, Instytutu im. Jerzego Grotowskiego, Teatru Kana, Teatru Brama. reż. Katarzyna Pawłowska",
      date: "2017 - 2018r.",
      category: {
        tag: "TRIODRAM ",

        color: "lightgray"
      }
    },
    {
      text: "reż.Jarosław Fret",
      date: "2016 - do teraz",
      category: {
        tag: "MEDEE. O PRZEKRACZANIU ",

        color: "lightgray"
      }
    },
    {
      text: "reż. Jarosław Fret(Teatr ZAR)",
      date: "2014 - 2017r.",
      category: {
        tag: "ARMINE,SISTER ",

        color: "lightgray"
      }
    },
    {
      text: "reż.Daniel Jacewicz",
      date: "2010 - 2012r.",
      category: {
        tag: "APOTEOZA - UCZUCIE W DŹWIĘKU ",

        color: "lightgray"
      }
    },
    {
      text: "reż.Dariusz Kowcun",
      date: "2010 - 2012r.",
      category: {
        tag: "Faust - 7 grzechów głównych ",

        color: "lightgray"
      }
    },
    {
      text: "reż. Dariusz Kowcun",
      date: "2009 - 2010r.",
      category: {
        tag: "DRUGI POKÓJ ",

        color: "lightgray"
      }
    }
  ];
}
export function travels() {
  return [
    {
      text: "ANHELLI. SKOWYT, teatr ZAR",
      date: "2019r.",
      category: {
        tag:
          "Olimpiada Teatralna w Todze (Japonia) i Sankt-Petersburgu (Rosja)",

        color: "lightgray"
      }
    },
    {
      text: "ANHELLI. SKOWYT, teatr ZAR",
      date: "2019r.",
      category: {
        tag: "Teatr X w Tokio, Japonia",

        color: "lightgray"
      }
    },
    {
      text: "Teatr ZAR",
      date: "2019r.",
      category: {
        tag: "Festiwal Pieśni Polifonicznych, Sardynia",

        color: "lightgray"
      }
    },
    {
      text: "MEDEE. O PRZEKRACZANIU, teatr ZAR",
      date: "2018r.",
      category: {
        tag: "Teatr Bouffes Du Nord w Paryżu, Francja",

        color: "lightgray"
      }
    },
    {
      text: "KRÓTKI ZARYS WSZYSTKIEGO, Cloud Theater",
      date: "2018r.",
      category: {
        tag: "Frankfurt Nad Niemiem, Niemcy",

        color: "lightgray"
      }
    },
    {
      text: "Technologia jest istotą, Cloud Theater",
      date: "2018r.",
      category: {
        tag: "Top OFF Festiwal w Tychach",

        color: "lightgray"
      }
    },
    {
      text: "Technologia jest istotą, Cloud Theater",
      date: "2017r.",
      category: {
        tag: "38 Przegląd Piosenki Aktorskiej, Wrocław",

        color: "lightgray"
      }
    },
    {
      text: "Triodram",
      date: "2017r.",
      category: {
        tag: "Festiwal OFTeN w Ostrowie Wielkopolskim; nagroda Grand Prix",

        color: "lightgray"
      }
    },
    {
      text: "Medee. O Przekraczaniu, teatr ZAR",
      date: "2017r.",
      category: {
        tag: "Forest Festival w Salonikach, Grecja",

        color: "lightgray"
      }
    },
    {
      text: [
        { title: "Triodram " },
        { title: " 3 nagrody:" },
        { text: "1. Złoty OFMAT - Nagroda Redakcji" },
        { text: "2. Nagroda Młodego Jury" },
        { text: "3. III-cie wyróżnienie" }
      ],
      date: "2017r.",
      category: {
        tag: "Festiwal OFMAT w Krakowie",

        color: "lightgray"
      }
    },
    {
      text: "ARMINE,SISTER, teatr ZAR",
      date: "2017r.",
      category: {
        tag: "Iberico Theater w Lizbonie, Portugalia",

        color: "lightgray"
      }
    },
    {
      text: "ARMINE,SISTER oraz MEDEE.O PRZEKRACZANIU, teatr ZAR",
      date: "2017r.",
      category: {
        tag: "Naves Matadero w Madrycie, Hiszpania",

        color: "lightgray"
      }
    },
    {
      text: "ARMINE,SISTER, teatr ZAR",
      date: "2016r.",
      category: {
        tag: "International Theatre Festival w Sibiu, Rumunia",

        color: "lightgray"
      }
    },
    {
      text: "MEDEE. O PRZEKRACZANIU, teatr ZAR",
      date: "2016r.",
      category: {
        tag: "Olimpiada Teatralna we Wrocławiu",

        color: "lightgray"
      }
    },
    {
      text: "ARMINE,SISTER, teatr ZAR",
      date: "2015r.",
      category: {
        tag: "Kanonhallen w Oslo, Norwegia ",

        color: "lightgray"
      }
    },
    {
      text: "ARMINE,SISTER, teatr ZAR",
      date: "2015r.",
      category: {
        tag: "Fort Mason Center w San Francisco, Stany Zjednoczone",

        color: "lightgray"
      }
    },
    {
      text: "ARMINE,SISTER, teatr ZAR",
      date: "2015r.",
      category: {
        tag: "Teatro India w Rzymie, Włochy",

        color: "lightgray"
      }
    },
    {
      text: "ARMINE,SISTER, teatr ZAR",
      date: "2015r.",
      category: {
        tag: "Festiwal SPOIWA w Szczecinie, Polska",

        color: "lightgray"
      }
    },
    {
      text: "ARMINE,SISTER, teatr ZAR",
      date: "2015r.",
      category: {
        tag: "Theatre de la Tempete w Paryżu, Francja",

        color: "lightgray"
      }
    },
    {
      text: "ARMINE,SISTER, teatr ZAR",
      date: "2014r.",
      category: {
        tag: "Battersea Arts Centre w Londynie, Anglia",

        color: "lightgray"
      }
    }
  ];
}
export function practices() {
  return [
    {
      text: [
        { title: "Kameron Steele", text: " - Suzuki Company of Toga" },
        { title: "Vivien Wood", text: " - DV8 Physical Theatre" },
        { title: "Przemysław Błaszczak", text: "- teatr ZAR, Studio Kokyu" },
        {
          title: "Jakub Gontarski",
          text:
            " - instruktor rekreacji ruchowej ze specjalizacją: samoobrona, kaskader, certyfikowany trener personalny oraz trener ruchu pod kierunkiem Steve'a Maxwell'a."
        },
        { text: "Praktyka pieśni korsykańskich w Pianello, Korsyka." },
        {
          title: "Aram Kerovpyan",
          text: " - praktyka modalnych pieśni ormiańskich"
        },
        {
          title: "Jakub Gontarski",
          text: " - Studio Dwóch Ścieżek BodyConstitution"
        },
        { title: "Savvas Stroumpos", text: "- Theatre ATTIS" }
      ],
      date: "2018 - 2019 r.",
      category: {
        tag: "Trening indywidualny z:",

        color: "lightgray"
      }
    },
    {
      text: [
        {
          text:
            "Projekt z ramienia Ministerstwa Kultury i Dziedzictwa Narodowego, Instytutu im. Jerzego Grotowskiego, teatru Kana, teatru Brama."
        },
        {
          text:
            "Warsztaty i treningi m.in. z Teatrem Chorea, Trzecim Teatrem Lecha Raczaka, Teatrem ATTIS, Studiem Teatralnym z Warszawy, Teatrem Cinema, Teatrem Kana, Akademią Ruchu, Teatrem Stella Polaris, Teatr im. Łesia Kurbasa, Anną Zubrzycki, Artim Grabowskim i Jackiem Hałasem."
        }
      ],
      date: "2014 - 2017 r.",
      category: {
        tag: "Akademia Teatru Alternatywnego:",

        color: "lightgray"
      }
    }
  ];
}
